.logoStyle {
    display: flex;
    height: 10vh;
    justify-content: center;
    align-items: center;
    margin: 0 8% 0;
}

.logoStyle a{
    display:flex;
}

.image {
    height: 8vh;
}

@media only screen and (max-width: 600px) {
    .logoStyle {
        margin: 0;
    }
}