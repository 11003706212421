.title {
    margin: 0 0;
    padding: 20px 0;
    font-weight: normal;
}

.horizontalLine{
    width: 100%;
    color: #686868;
    border-width: 3px;
    border-style: solid;
    border-radius: 20px;
    margin: 4px 0;
    box-sizing: border-box;
    border-bottom:thin;
}