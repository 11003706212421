.paralaxEffect {
    background-image: url('../../public/assets/general/parallax_IR.webp');
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.paralaxContentWrapper {
    display: flex;
    max-width: 100%;
    height: inherit;
    justify-content: center;
    align-items: center;
    position: fixed;
    flex-direction: column;
}

.paralaxArrowWrapper {
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}

.paralaxContent {
    max-width: 700px;
    white-space: pre-line;
    color: white;
}

.parallaxHeading {
    margin-top: 300px;
    font-weight: 400;
    letter-spacing: 7px;
}

.paralaxArrow {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
    height: 10px;
    width: 10px;
}

.paralaxArrowColor {
    border-bottom-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
}