@import"Global.module.scss";

.footerStyle {
    display: flex;
    background-color: $blackBackground;
    max-height: 40vh; //change later
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
    flex-direction: column;
    padding: 15px 0;
    margin-top: 60px;
    width: 100%;
    min-height: 10vh;
    z-index: 1;
}

.copyright {
    margin: 5px 0;
    font-size: 11px;
    color: $darkGreyLetters;
}

.instagramFeed {
    width: 90%;
    height: 250px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%
}

.galleryImage {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    object-fit: contain;
}

.imageWrapper {
    display: flex;
    height: 90%
}

@media (max-width: 768px) {
    .instagramFeed {
        display: none;
    }
}