@import"Global.module.scss";

//START Ordinary navbar styling
.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-right: 8%;
}

.navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar li {
    display: inline;
    padding: 0 20px;
}

.navbar a {
    color: $lightLetters;
    text-decoration: none;
    text-transform: uppercase;
}

.navbar a:hover {
    color: $highlightColor;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

.active {
    color: $highlightColor !important;
}


//START Social Navbar Icons style
.socialNavbar {
    display: flex;
    align-items: center;
}

.socialNavbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inherit;
}

.socialNavbar a {
    display: flex;
    width: 30px;
    padding: 0 5px;
}

.socialNavbar a:hover path {
    stroke: $lightLetters;
    fill: $highlightColor;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

//START Social Navbar Icons mobile style

.mobileMenuWrapper{
    margin-right: 5%;
    width:100%;
}

.socialNavbarMobile{
    border-top: 1px solid #000000;
    display: flex;
    justify-content: center;
    height: 200px;
}

.socialNavbarMobile ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inherit;
    align-items: center;
}

.socialNavbarMobile a {
    display: flex;
    width: 30px;
    padding: 0 5px;
}

.socialNavbarMobile path{
    fill: #000000;
    transition: 0.5s;
}

.socialNavbarMobile a:hover path{
    fill: #f16522;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.burger_menu {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: large;
    font-weight: 600;
}

.burger_menu ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inherit;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4%;
}

.burger_menu a {
    color: $blackBackground;
    text-decoration: none;
    text-transform: uppercase;
}

.burger_menu a:hover {
    color: $highlightColor;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

.burger_line {
    width: 35px;
    height: 5px;
    background-color: #dbdbdb;
    margin: 3px 0;
    border-radius: 20px;
    transition: background-color 0.5s;
}

.burger_icon:hover .burger_line{
    background-color: #f16522;
}

.burger_icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    transition: background-color 0.5s;
}

.mobileMenu{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    display:flex;
    flex-direction: column;
}

.close_icon {
    width: 100px;
    height: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
}

.close {
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close:after {
    content: '';
    height: 35px;
    border-left: 4px solid #000000;
    position: absolute;
    transform: rotate(45deg);
    border-radius: 20px;
    transition: border-left 0.5s;
}

.close:before {
    content: '';
    height: 35px;
    border-left: 4px solid #000000;
    position: absolute;
    transform: rotate(-45deg);
    border-radius: 20px;
    transition: border-left 0.5s;
}

.close:hover::after {
    border-left: 4px solid #f16522;
}

.close:hover::before {
    border-left: 4px solid #f16522;
}

body:has(.no-scroll){
    overflow: hidden;
}