@import "Global.module.scss";

.bodyStyle {
    display: flex;
    //background-color: GlobalStyle.$darkGreyLetters;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 600px;
    max-width: 1400px;
    z-index: 0;
    background-color: white;
    padding-top: 60px;
}

.bodyTextWrapper{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bodyStyleVertical{
    display: flex;
    //background-color: GlobalStyle.$darkGreyLetters;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.coverGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
    gap: 1vw;
    min-height: 500px;
}

.coverGallery>a {
    width: 30%;
    display: flex;
}

.linkShowcaseWrapper {
    position: relative;
}

.linkShowcaseWrapper:hover .overlay {
    opacity: 1;
}

.imageShowcase {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.705);
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.4s ease;
}

.coverImageOverlay {
    flex-direction: column;
}

.coverImageOverlay span {
    displaY: flex;
    justify-content: center;
}

.overlay p {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 16px;
}

.overlay img {
    height: 40px;
}

.aboutStyle {
    display: flex;
    flex-direction: column;
}

.contactText{
    margin-top:70px;
    width: 70%
}

.shopItems{
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: center;
}

//Media Querries

@media (max-width: 768px) {
    .gallery {
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .coverGallery>a {
        width: 49%;
    }

    .shopItems{
        flex-direction: column;
    }
}

@media only screen and (max-width: 600px) {
    .coverGallery>a {
        width: 100%;
    }

    .coverGallery {
        gap: 10px 0;
    }
}