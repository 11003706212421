//Colors used across whole page
$grayBackground: #dbdbdb;
$highlightColor: #f16522;
$blackBackground: #2f2e2e;
$darkGreyLetters: #7C7C7C;
$lightLetters: #dbdbdb;
$buttonTextColor: #4d4d4d;
$buttonHighlightTextColor: white;
$buttonBackgroundColor: #e2e2e2;

@font-face {
    font-family: "PierSans";
    src: url("../assets/fonts/PierSans/PierSans-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PierSans";
    src: url("../assets/fonts/PierSans/PierSans-Italic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "PierSans";
    src: url("../assets/fonts/PierSans/PierSans-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "PierSans";
    src: url("../assets/fonts/PierSans/PierSans-BoldItalic.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "PierSans";
    src: url("../assets/fonts/PierSans/PierSans-Light.otf") format("opentype");
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: "PierSans";
    src: url("../assets/fonts/PierSans/PierSans-LightItalic.otf") format("opentype");
    font-weight: lighter;
    font-style: italic;
}

.wrapper {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    width: 100%;
    align-items: center;
}

.bodyWrapper {
    max-width: 1900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.buttonStyle {
    height: 35px;
    border: none;
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.5s ease;
    border-radius: 4px;
    font-family: "PierSans";
    padding: 0 20px;
}

.buttonStyle:hover {
    background-color: $highlightColor;
    color: $buttonHighlightTextColor;
    transition: all 0.5s ease;
}

.galleryImage {
    width: 100%;
    padding: 20px 0;
}

.galleryDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
}

.galleryDetails p {
    margin: 0px 0px;
    text-align: center;
}

.galleryDetails span {
    display: flex;
}

.galleryTextArea {
    margin-bottom: 10px;
}

.textSectionDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
}

p {
    white-space: pre-line;
    text-align: justify;
    font-family: "PierSans";
    font-style: normal;
    font-weight: lighter;
    color: $blackBackground;
    font-size: 14px;
}

h3 {
    font-family: "PierSans";
    font-style: normal;
    font-weight: lighter;
    color: $blackBackground;
    margin: 14px 0;
}

.iconStyle {
    display: flex;
    height: 50px;
    margin: 14px 14px;
}

.linkSectionWrapper {
    margin: 20px 50px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
}

.imageStyle {
    max-width: 300px;
}

@media only screen and (max-width: 1000px) {
    .galleryDetails {
        max-width: 100%;
    }
}