.accordion {
    display: flex;
    list-style-type: none;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    justify-content: center;
    height: 50vh;
    margin-top: 60px;
}

.item {
    background-color: #ffffff3f;
    display: flex;
    flex: 1;
    //margin: 0 0.2%;
    transition: background-color, flex 1s ease;
}

.item.active {
    flex: 1 1 500px;
}

.visibleContent {
    display: flex;
    //flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    //overflow: hidden;
    //background-size: cover;
    //background-position: center center;
    //background-repeat: no-repeat;
    //box-shadow: inset 0 -10px 20px -5px #f8a100;
    flex: 1 0 0;
}

.visibleContentActive{
    opacity: 1;
    //box-shadow: inset 0 -100px 70px -20px #f8a100;
}

.accordionOverlay{
    flex-direction: column;
    justify-content: flex-start;
}

.hiddenContent {
    width: 0;
    opacity: 0;
    overflow: hidden;
    padding-top: 50px;
    transition: flex 0.7s ease, opacity 1s ease 0.7s;
}

.hiddenContent h2{
    padding-top: 0px;
}

.baseContentStyle {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
}

.baseContentStyle p{
    text-align: center;
}

.baseTextStyle {
    margin: 0;
    align-items: baseline;
    padding-top: 50px;
    font-size: x-large;
    font-weight: 500;
}

.open {
    flex: 1;
    opacity: 1;
    word-wrap: break-word;
}

.close {
    opacity: 0;
    flex: 0;
    transition: flex 1s ease 0.3s, opacity 0.2s;
}

.accordionShowText {
    opacity: 1;
}

.hovering {
    flex: 1.1;
}

.accordionImage{
    object-fit: cover;
    height: 100%;
    width: 100%;
}